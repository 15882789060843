<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div v-show="alertMsg" class="alert bg-warning alert-dismissible" role="alert"> <strong>Informasi !</strong> Mohon lengkapi data ini </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman update biodata
            </p>
          </div>
          <div class="card-body padding-t-0">
            <form  role="form" method="post" action="">
              <h5 class="mb-0">DATA POKOK</h5>
              <p id="idExsampel" class="text-muted mb-0" >
              <small>Halaman Perubahan data Pokok</small>
              </p>
              <div class="form-group">
                <label>N I K <small>(Nomor Induk Keluarga / NIK KTP)</small></label>
                <input type="text" class="form-control col-sm-12 col-md-8" placeholder=""  v-model="valName.nik_mahasiswa" required>
              </div>
              <div class="form-group ">
                <label>Nama Lengkap</label>
                <input type="text" class="form-control form-control-rounded" v-model="valName.nama_mahasiswa" required>
              </div>
              <div class="form-group">
                <label>Tempat Lahir</label>
                <input type="text" class="form-control col-sm-12 col-md-10" placeholder="Tempat kelahiran"
                  v-model="valName.tempat_lahir">
              </div>
              <div class="form-group">
                <label>Tanggal Lahir</label>
                <div class="input-group m-b col-sm-12 col-md-5">
                  <span class="input-group-addon"><i class="glyphicon glyphicon-calendar fa fa-calendar"></i></span>
                  <input type="text" class="tglLhr date" v-model="valName.tanggal_lahir" />
                </div>
              </div>
              <div class="form-group">
                <label>Jenis Kelamin</label>
                <select v-model="valName.jenis_kelamin" class="form-control col-sm-12 col-md-5">
                  <option v-for="jk in setKelamin" v-bind:value="jk.id" :key="jk.id">
                    {{ jk.nama }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Agama</label>
                <select v-model="valName.id_agama" class="form-control col-sm-12 col-md-5">
                  <option v-for="agama in rowsAgama" v-bind:value="agama.id" :key="agama.id">
                    {{ agama.nama }}
                  </option>
                </select>
              </div>
              <h5 class="mb-0 mt-20">DATA ORANGTUA</h5>
              <p id="idExsampel" class="text-muted mb-0" >
                <small>Halaman Perubahan Informasi Orang Tua</small>
              </p>
              <div class="form-group">
                <label>Nama Ibu Kandung <small class="text-danger">*Tanpa gelar</small></label>
                <input type="text" class="form-control col-sm-12 col-md-8" placeholder="" v-model="valName.nama_ibu_kandung" required>
              </div>
              <div class="form-group">
                <label>Nama Ayah Kandung <small class="text-danger">*Tanpa gelar</small></label>
                <input type="text" class="form-control col-sm-12 col-md-8" placeholder="" v-model="valName.nama_ayah">
              </div>
              <h5 class="mb-0 mt-20">DATA PENDIDIKAN</h5>
              <p id="idExsampel" class="text-muted mb-0" >
              <small>Halaman Perubahan Informasi Pendidikan</small>
              </p>
              <div class="form-group">
                <label>N I S N / N P M <small class="text-danger">(Nomor Induk Siswa Nasional atau N P M)</small></label>
                <input type="text" class="form-control col-sm-12 col-md-8" placeholder="" v-model="valName.nisn_mahasiswa" required>
              </div>
              <div class="form-group">
                <label>Nama Asal Sekolah / Perguruan Tinggi</label>
                <input type="text" class="form-control col-sm-12 col-md-12" placeholder="" v-model="valName.nm_pt_asal" required>
              </div>
              <h5 class="mb-0 mt-20">DATA KONTAK</h5>
              <p id="idExsampel" class="text-muted mb-0" >
              <small>Halaman Perubahan Informasi Kontak</small>
              </p>
              <div class="form-group">
                <label>Alamat Jalan <small class="text-danger">*lihat KK</small></label>
                <input type="text" class="form-control col-sm-12 col-md-11" placeholder="" v-model="valName.alamat_jalan" required>
              </div>
              <div class="form-group">
                <label>Nama Dusun <small class="text-danger">*lihat KK</small></label>
                <input type="text" class="form-control col-sm-12 col-md-11" placeholder="" v-model="valName.nama_dusun" required>
              </div>
              <div class="form-group">
                <label>Nama Kelurahan <small class="text-danger">*lihat KK</small></label>
                <input type="text" class="form-control col-sm-12 col-md-11" placeholder="" v-model="valName.nama_kelurahan" required>
              </div>
              <div class="form-group">
                <label>RT / RW <small class="text-danger">*lihat KK</small></label>
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <input type="text" class="form-control" placeholder="RT" v-model="valName.rt" required>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <input type="text" class="form-control" placeholder="RW" v-model="valName.rw" required>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Nama Wilayah <small>Prop. Kab. Kec. </small></label>
                <select class="wilayah form-control col-sm-12 col-md-12" data-placeholder="" data-allow-clear="1"
                  v-model="valName.id_wilayah">
                  <option v-for="wil in rowsWilayah" v-bind:value="wil.id" :key="wil.id" selected="selected">
                    {{ wil.nama }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Kode Pos</label>
                <input type="text" class="form-control col-sm-12 col-md-4" placeholder="" v-model="valName.kode_pos">
              </div>
              <div class="form-group">
                <label>Nomor HP <small class="text-danger">*No harus Aktif</small></label>
                <input type="text" class="form-control col-sm-12 col-md-11" placeholder="" v-model="valName.nomor_hp">
              </div>
              <div class="form-group">
                <label>Alamat Email <small class="text-danger">*Email harus Aktif</small></label>
                <input type="text" class="form-control col-sm-12 col-md-11" placeholder="" v-model="valName.alamat_email">
              </div>
              <!--
              <div class="form-group">
                <label>Konfirmasi Perubahan Data </label>
                <input type="password" name="password" class="form-control col-sm-12 col-md-6" placeholder="Masukan Password SIAKAD" v-model="valName.password">
                <small class="text-muted">Masukan Akun SSO agar kami bisa memastikan bahwa benar saudara adalah pemelik akun ini.</small>
              </div>
              -->
              <button type="button" v-on:click="submit" class="btn btn-success btn-rounded box-shadow">Update Perubahan</button> &nbsp;
              <router-link to="/" class="btn btn-warning btn-rounded box-shadow">Kembali</router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* global $ */
import Loading from 'vue-loading-overlay'
import { getAgama, getWilayah } from '@/services/other.service'
import { getMhs, putMhs } from '@/services/mahasiswa.service'
import { User } from '@/store/mutation'
import { useStore } from 'vuex'
export default {
  name: 'EditBiodata',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      authRow: users,
      rowMhs: {},
      rowsAgama: [],
      rowsWilayah: [],
      valName: {},
      setKelamin: [{ id: 'P', nama: 'Perempuan' }, { id: 'L', nama: 'Laki-Laku' }],
      searchWill: '',
      loadData: false,
      loadSubmit: false,
      loadWilayah: false,
      loadAgama: false,
      fullPage: true,
      errorMsg: ''
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadSubmit || this.loadWilayah || this.loadAgama))
      return load
    },
    alertMsg: function () {
      const load = (this.valName.updated_at === null)
      return load
    }
  },
  created () {
    this.handleBiodata()
    this.handleAgama()
  },
  mounted () {
    const vm = this
    $('.tglLhr').datepicker({
      clearBtn: true,
      autoclose: true,
      format: 'dd/mm/yyyy'
    }).on('changeDate', function (ev) {
      vm.valName.tanggal_lahir = ev.target.value
    })
    $('.wilayah').select2({
      ajax: {
        transport: function (params, success, failure) {
          var searchData = {
            nama: params.data.term
          }
          getWilayah(searchData)
            .then(function (response) {
              const rows = response.records
              const setData = rows.map((row, index) => {
                const val = {}
                val.id = row.id
                val.text = row.nama
                return val
              })
              const result = { results: setData }
              success(result)
            })
            .catch(function (error) {
              console.log('error : ', error)
            })
        }
      },
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.valName.id_wilayah = e.params.data.id
    })
  },
  methods: {
    async handleAgama () {
      this.loadAgama = true
      getAgama({})
        .then(response => {
          if (response.errorcode === 200) {
            this.rowsAgama = response.records
          }
          this.loadAgama = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadAgama = false
        })
    },
    async handleWilayah () {
      this.loadWilayah = true
      getWilayah({ id: this.valName.id_wilayah })
        .then(response => {
          if (response.error_code === 200) {
            this.rowsWilayah = response.records
          }
          this.loadWilayah = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadWilayah = false
        })
    },
    async handleBiodata () {
      this.loadData = true
      getMhs({ nipd: this.authRow.username })
        .then((response) => {
          this.isLoading = false
          if (response.status !== 200) {
            this.showError = true
          } else {
            this.loadData = false
            this.valName = response.records
            User.updated_at = this.valName.updated_at
            $('.tglLhr').datepicker('setDate', new Date(this.valName.tanggal_lahir))
            this.handleWilayah()
          }
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadData = false
        })
    },
    async submit () {
      this.loadSubmit = true

      if (
        !this.valName.nik_mahasiswa ||
        !this.valName.nisn_mahasiswa ||
        !this.valName.nama_ibu_kandung ||
        !this.valName.nama_kelurahan ||
        !this.valName.nomor_hp ||
        !this.valName.alamat_email ||
        !this.valName.nm_pt_asal
      ) {
        this.loadSubmit = false
        $.toast({
              heading: 'Error !',
              text: 'Data tidak boleh ada yang kosong',
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'error',
              hideAfter: 10000,
              stack: 1
            })
        return
      }
      
      putMhs(this.valName)
        .then(response => {
          if (response.error_code === 200) {
            $.toast({
              heading: 'Good job!',
              text: 'Data akun spada berhasil dibuat',
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'success',
              hideAfter: 3000,
              stack: 1
            })
            this.handleBiodata()
            this.loadSubmit = false
          } else {
            $.toast({
              heading: 'Error !',
              text: response.messages,
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'error',
              hideAfter: 10000,
              stack: 1
            })
            this.loadSubmit = false
          }
        }).catch(error => {
          this.loadSubmit = false
          this.errorMsg = (error.response) ? {} : {}
        })
    }
  }
}
</script>
